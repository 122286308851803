import * as React from 'react'
import { useState } from 'react'
import { FunctionComponent } from 'react'
import { DialogActions } from '@material-ui/core'
import { FormInput } from '../components/sharedStyles'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Mutation from 'react-apollo/Mutation'
import { CREATE_PROPERTY } from '../apollo/mutations'
import { GET_USER_PROPERTIES } from '../apollo/queries'

interface WelcomeProps {
	name: string
}

const ErrorSnackbar = styled(Snackbar)`
	& > div {
		background-color: darkred;
	}
`;

const CreateProperty: FunctionComponent<WelcomeProps> = props => {
	const [houseNumber, setHouseNumber] = useState('')
	const [streetName, setStreetName] = useState('')
	const [city, setCity] = useState('')
	const [county, setCounty] = useState('')
	const [state, setState] = useState('')
	const [zipCode, setZipCode] = useState('')

	return (
		<>
			<Mutation
				mutation={CREATE_PROPERTY}
				refetchQueries={[
					{ query: GET_USER_PROPERTIES },
					'PropertiesIdList'
				]}
				awaitRefetchQueries={true}
			>
				{(CreateProperty, { loading, error, data }) => (
					<div>
						<form
							onSubmit={e => {
								e.preventDefault()
								CreateProperty({
									variables: {
										houseNumber,
										streetName,
										city,
										county,
										state,
										zipCode,
									},
								})
							}}
						>
							<FormInput
								error={error !== undefined}
								id="houseNumber"
								label={'House Number'}
								onChange={e => setHouseNumber(e.target.value)}
								margin="normal"
								value={houseNumber}
							/>
							<FormInput
								error={error !== undefined}
								id="streetName"
								label={'Street Name'}
								onChange={e => setStreetName(e.target.value)}
								margin="normal"
								value={streetName}
							/>
							<FormInput
								error={error !== undefined}
								id="city"
								label={'City'}
								onChange={e => setCity(e.target.value)}
								margin="normal"
								value={city}
							/>
							<FormInput
								error={error !== undefined}
								id="county"
								label={'County'}
								onChange={e => setCounty(e.target.value)}
								margin="normal"
								value={county}
							/>
							<FormInput
								error={error !== undefined}
								id="state"
								label={'State'}
								onChange={e => setState(e.target.value)}
								margin="normal"
								value={state}
							/>
							<FormInput
								error={error !== undefined}
								id="zipCode"
								label={'Zip Code'}
								onChange={e => setZipCode(e.target.value)}
								margin="normal"
								value={zipCode}
							/>

							<DialogActions>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									<Button disabled={loading} type="submit">
										Login
									</Button>
								)}
							</DialogActions>
							<ErrorSnackbar
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								open={error !== undefined}
								autoHideDuration={3000}
								onClose={() => null}
								ContentProps={{
									'aria-describedby': 'message-id',
								}}
								message={<span id="message-id">Error creating account. Please retry.</span>}
							/>
						</form>
					</div>
				)}
			</Mutation>
		</>
	)
}

export default CreateProperty
